import { environment } from 'src/environments/environment';
import { RepositoryType } from '../pages/project-dashboard/project-dashboard-repository/document-metadata-types';

export const tenantIds = {
  consileon: { documentLocator: 'filename' },
  [RepositoryType.Websites]: { documentLocator: 'url' },
};

export class TenantUtil {
  static determineTenantIdFromTenantAlias(tenant: string) {
    return environment.tenants[tenant].id;
  }

  static findTenantAliasById(idToFind: string): string | undefined {
    return Object.keys(environment.tenants).find((key) => environment.tenants[key].id === idToFind);
  }

  static loginMethodAvailable(tenantAlias: string, loginMethod: string) {
    return environment.tenants[tenantAlias].loginmethods.includes(loginMethod);
  }
}
